const deleteCourseRegistrationEls = document.querySelectorAll('.js-delete-course-registration');
if (deleteCourseRegistrationEls) {
    for (let i = 0; i < deleteCourseRegistrationEls.length; i++) {
        deleteCourseRegistrationEls[i].addEventListener('click', function(event) {
            event.preventDefault();

            Swal.fire({
                title: 'Ben je zeker?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Annuleer',
                confirmButtonColor: '#57b8ff',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = deleteCourseRegistrationEls[i].href;
                }
            });
        });
    }
}