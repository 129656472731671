const typeEl = document.getElementById('booking_info_type');
const groupTypeContainer = document.querySelector('.js-group-type');
const groupTypeEl = document.getElementById('booking_info_groupType');
const groupInfoEl = document.querySelector('.js-group-info');

const $typeEl = $(typeEl).select2();
const $groupTypeEl = $(groupTypeEl).select2();

const purposeContainer = document.querySelector('.js-purpose');
const isIvvBookingElement = document.querySelector('.js-statistics-ivv-booking');
const accommodationEls = document.querySelectorAll('input[name="booking_info[entity]"]');
const selectedAccommodationEl = document.querySelector('input[name="booking_info[entity]"]:checked');

const form = document.querySelector('[name="booking_info"]')
const nextStepEl = document.querySelector('.js-statistics-next-step');
const ignoreMonthEl = document.querySelector('.js-statistics-ignore-month');
const purgeMonthEl = document.querySelector('.js-statistics-purge-month');

let showGroupInfo = null;
let isHostel = null;
let isIvv = null;
if (selectedAccommodationEl) {
    showGroupInfo = selectedAccommodationEl.dataset.showGroupInfo;
    isHostel = selectedAccommodationEl.dataset.isHostel;
    isIvv = selectedAccommodationEl.dataset.isIvv;
}

let type = $('#booking_info_type option:selected').val();
let groupType = $('#booking_info_groupType option:selected').val();

toggleGroupInfo(type, groupType);
togglePurposeElement(isHostel);
toggleIsIvvBooking(isIvv);

$typeEl.on('change', (event) => {
    groupTypeContainer.classList.toggle('hidden');

    type = $('#booking_info_type option:selected').val();
    groupType = $('#booking_info_groupType option:selected').val();

    toggleGroupInfo(type, groupType);
});

$groupTypeEl.on('change', (event) => {
    type = $('#booking_info_type option:selected').val();
    groupType = $('#booking_info_groupType option:selected').val();

    toggleGroupInfo(type, groupType);
});

for (var i = 0; i < accommodationEls.length; i++) {
    accommodationEls[i].addEventListener('change', function() {
        type = $('#booking_info_type option:selected').val();
        groupType = $('#booking_info_groupType option:selected').val();
        showGroupInfo = this.dataset.showGroupInfo;
        isHostel = this.dataset.isHostel;
        isIvv = this.dataset.isIvv;

        togglePurposeElement(isHostel);
        toggleIsIvvBooking(isIvv);
        toggleGroupInfo(type, groupType);
    });
}

const checkInDateEl = document.getElementById('booking_info_checkInDate');
const checkOutDateEl = document.getElementById('booking_info_checkOutDate');
if (nextStepEl) {
    nextStepEl.addEventListener('click', function(event) {
        event.preventDefault();

        let checkInDate = moment(checkInDateEl.value, 'DD/MM/YYYY');
        let checkOutDate = moment(checkOutDateEl.value, 'DD/MM/YYYY');
        let numberOfDays = Math.abs(checkInDate.diff(checkOutDate, 'days'));

        if (isNaN(numberOfDays) || numberOfDays < 15) {
            form.submit();

            return;
        }

        Swal.fire({
            title: 'Ben je zeker?',
            text: 'De periode is langer dan 15 nachten.',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Annuleer',
            confirmButtonColor: '#57b8ff',
        }).then((result) => {
            if (result.isConfirmed) {
                form.submit();
            }
        });
    });
}

if (ignoreMonthEl) {
    ignoreMonthEl.addEventListener('click', function(event) {
        event.preventDefault();

        Swal.fire({
            title: 'Ben je zeker dat je deze maand wil negeren?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Annuleer',
            confirmButtonColor: '#57b8ff',
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = ignoreMonthEl.href;
            }
        });
    });
}

if (purgeMonthEl) {
    purgeMonthEl.addEventListener('click', function(event) {
        event.preventDefault();

        Swal.fire({
            title: 'Ben je zeker dat je alle boekingen van deze maand wil wissen?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Annuleer',
            confirmButtonColor: '#57b8ff',
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = purgeMonthEl.href;
            }
        });
    });
}

const deleteEntriesEl = document.querySelectorAll('.js-delete-statistic-entry');
if (deleteEntriesEl) {
    for (let i = 0; i < deleteEntriesEl.length; i++) {
        deleteEntriesEl[i].addEventListener('click', function(event) {
            event.preventDefault();

            Swal.fire({
                title: 'Ben je zeker?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Annuleer',
                confirmButtonColor: '#57b8ff',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = deleteEntriesEl[i].href;
                }
            });
        });
    }
}

function toggleGroupInfo(type, groupType) {
    if (!groupInfoEl) {
        return;
    }

    if ('individual' === type && !groupInfoEl.classList.contains('hidden')) {
        groupTypeContainer.classList.add('hidden');
        groupInfoEl.classList.add('hidden');

        return;
    }

    if ('A' === groupType && showGroupInfo) {
        if (groupInfoEl.classList.contains('hidden')) {
            groupInfoEl.classList.toggle('hidden');
        }
    }

    if (('A' !== groupType || !showGroupInfo) && !groupInfoEl.classList.contains('hidden')) {
        groupInfoEl.classList.add('hidden');
    }
}

function togglePurposeElement(isHostel) {
    if (!purposeContainer) {
        return;
    }

    isHostel == undefined ? purposeContainer.classList.add('hidden') : purposeContainer.classList.remove('hidden');
}

function toggleIsIvvBooking(isIvv) {
    if (null === isIvvBookingElement) {
        return;
    }

    null !== isIvv && undefined !== isIvv ? isIvvBookingElement.classList.remove('hidden') : isIvvBookingElement.classList.add('hidden');
}