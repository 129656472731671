import axios from "axios";

const toggleCalendarSwitch = document.querySelector('#toggleCalendarEnabledElement');

if (toggleCalendarSwitch) {

    toggleCalendarSwitch.addEventListener('input', function(e){
        console.log(e, e.target.checked);
        let form = new FormData();
        form.append('calendarEnabled', e.target.checked)

        axios.post(toggleCalendarSwitch.dataset.url, form)
            .catch(error => {
                console.error(error)
            });
    });

}