import axios from "axios";

const toggleFollowUpFlags = document.querySelectorAll('.follow-up-flag');

if (toggleFollowUpFlags) {

    toggleFollowUpFlags.forEach(function(checkbox) {
        checkbox.addEventListener('change', function(e) {
            e.target.setAttribute("disabled", "disabled");

            if (e.target.checked) {
                const row = e.target.closest(".follow-up-row")

                const json = JSON.stringify({ uuid: e.target.value });
                axios.post(checkbox.dataset.url, json)
                    .catch(error => {
                        console.error(error)
                    }).then((response) => {
                    row.innerHTML = response.data
                })
            }
        })
    });

}
