const yearSelectorEl = document.querySelector('.js-select-annual-report-year');
const logisSelectorEl = document.querySelector('.js-select-annual-report-logis');
const formEl = document.querySelector("[name='widget_filter']");
const spinnerEl = document.querySelector('.js-annual-report-spinner');

if (yearSelectorEl) {
    $(yearSelectorEl).on('change', () => {
        submitForm();
    });

    var optionFormat = (item) => {
        if (!item.id) {
            return item.text;
        }

        var span = document.createElement('span');
        const logis = item.element.dataset.logis;
        var template = '';

        template += '<div class="d-flex align-items-center">';
        template += '<div class="d-flex flex-column">'
        template += '<span class="fw-bold lh-1">' + item.text + '</span>';
        template += '<span><small>' + logis + '</small></span>';
        template += '</div>';
        template += '</div>';

        span.innerHTML = template;

        return $(span);
    }

    $(logisSelectorEl).select2({
        templateSelection: optionFormat,
        templateResult: optionFormat,
    }).on('change', () => {
        submitForm();
    });
}

function submitForm() {
    spinnerEl.classList.toggle('hidden');
    formEl.submit();
}