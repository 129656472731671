const calendars = document.querySelectorAll('.js_calendar');
const warningBubbleContainer = document.querySelector('.js_conflicts_container');

if (warningBubbleContainer) {
    const warningBubbleClose = document.querySelector('.js_dismiss_conflicts');

    warningBubbleClose.addEventListener('click', (event) => {
        event.preventDefault();
        const dismissUrl = event.target.href;

        fetch(dismissUrl, {
            method: "POST",
            data: {year: 2021},
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                warningBubbleContainer.remove();
                return response;
            })
            .catch(function(error) {
                toastr.error(trans('messages.default_error'))
                console.log(error);
            });
    });
}

if (calendars) {
    calendars.forEach(calendarEl => {
        let deleteUrl = calendarEl.dataset.deleteurl;
        let bookingUrl = calendarEl.dataset.bookingurl;
        const initialDate = calendarEl.dataset.initialdate;

        let events = [];
        let days = [];
        if (calendarEl.dataset.events) {
            events = JSON.parse(calendarEl.dataset.events);

            events.forEach((event) => {
                event.title = event.name ?? '';
                event.start = event.start_date;
                event.end = event.end_date;
                event.className = 'open';
                event.className = event.type;
                if (event.type === 'other_calendar') {
                    event.className = 'linked-booking';
                }
            });
        }

        if (calendarEl.dataset.days) {
            days = JSON.parse(calendarEl.dataset.days);
            days.forEach((day, key) => {
                // if (day.type_of_day == 'midweek') {
                //     delete days[key];
                //     console.log(key);
                //     return;
                // }
                day.start = day.date;
                day.end = day.date;
                day.className = day.type_of_day;
                if (day.conflict) {
                    day.className +=  ' has-conflict';
                }
                day.display = 'background';
                day.allDay = true;
                day.title = '';
            });
        }
        events = events.concat(days);

        let calendar = new FullCalendar.Calendar(calendarEl, {
            navLinks: false, // can click day/week names to navigate views
            editable: false,
            height: 700,
            locale: 'nl',
            aspectRatio: 1,
            headerToolbar: {
                start: 'title',
                center: '',
                end: 'prev,next'
            },
            validRange: {
                start: Date.parse(calendarEl.dataset.startdate),
                end: Date.parse(calendarEl.dataset.enddate)
            },
            firstDay: 1,
            displayEventEnd:true,
            events: events,
            eventTimeFormat: { // like '14:30:00'
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false
            },
            contentHeight: 'auto',
            initialDate: initialDate,
            eventClick: function(arg) {
                if(!arg.event.start) {
                    return;
                }

                if(arg.event.extendedProps.type !== 'booking_slot' && arg.event.extendedProps.type !== 'unavailable' && arg.event.extendedProps.type !== 'partially_booked') {
                    const startDate = arg.event.start;
                    const endDate = arg.event.end ? arg.event.end : arg.event.start;

                    Swal.fire({
                        html: `
                            <div class="event-title fw-bolder mb-3">${arg.event.title}</div>
                            <div class="event-content text-left">
                                <div>
                                    <small class="event-date-title">${trans('common.start_date')}</small>
                                    ${numberFormat(startDate.getDate())}-${numberFormat(startDate.getMonth() + 1)}-${startDate.getFullYear()} ${numberFormat(startDate.getHours())}:${numberFormat(startDate.getMinutes())}
                                </div>
                                <div>
                                    <small class="event-date-title">${trans('common.end_date')}</small>
                                    ${numberFormat(endDate.getDate())}-${numberFormat(endDate.getMonth() + 1)}-${endDate.getFullYear()} ${numberFormat(endDate.getHours())}:${numberFormat(endDate.getMinutes())}
                                </div>
                            </div>
                            ${arg.event.extendedProps.booking_id ? `<div class="event-reservation-link"><a target="_blank" class="btn btn-primary btn-sm" href="${bookingUrl.replace('__BOOKINGID__', arg.event.extendedProps.booking_id)}">${trans('common.view_booking')}</a></div>` : ''}
                        `,
                        icon: false,
                        showCancelButton: false,
                        showConfirmButton: false,
                        showCloseButton: true,
                    })
                } else {
                    const startDate = arg.event.start;
                    const endDate = arg.event.end ? arg.event.end : arg.event.start;

                    Swal.fire({
                        html: `
                            <div class="event-title fw-bolder mb-3">${arg.event.title}</div>
                            <div class="event-content text-left">
                                <div>
                                    <small class="event-date-title">${trans('common.start_date')}:</small>
                                    ${numberFormat(startDate.getDate())}-${numberFormat(startDate.getMonth() + 1)}-${startDate.getFullYear()} ${numberFormat(startDate.getHours())}:${numberFormat(startDate.getMinutes())}</div>
                                <div>
                                    <small class="event-date-title">${trans('common.end_date')}:</small> 
                                    ${numberFormat(endDate.getDate())}-${numberFormat(endDate.getMonth() + 1)}-${endDate.getFullYear()} ${numberFormat(endDate.getHours())}:${numberFormat(endDate.getMinutes())}</div>
                            </div>
                        `,
                        icon: false,
                        showCancelButton: false,
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: trans('calendar.remove_event.button'),
                        confirmButtonColor: '#ff606a'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: trans('calendar.remove_event.confirmation_title'),
                                text: trans('calendar.remove_event.confirmation_text'),
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#ff606a',
                                cancelButtonColor: '#E1E5E7',
                                confirmButtonText: trans('calendar.remove_event.confirmation_confirm'),
                                cancelButtonText: trans('calendar.remove_event.confirmation_cancel')
                            }).then((confirmation) => {
                                if (confirmation.isConfirmed) {
                                    window.deleteItem(deleteUrl.replace('__EVENTID__', arg.event.id));
                                }
                            })
                        }
                    })
                }
            }
        });

        calendar.render();

        document.querySelectorAll('.js_conflict').forEach(element => {
            element.addEventListener('click', function(e) {
                const goToDate = new Date(element.dataset.start)
                calendar.gotoDate(goToDate);
            });
        })
    })
}

function numberFormat(number) {
    return ("0" + number).slice(-2)
}

window.deleteItem = function(url) {
    fetch(url, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(function (response) {
            window.location.reload();
        })
        .catch(function(error) {
            toastr.error(trans('messages.default_error'))
            console.log(error);
        });
};

const increaseConflictCounter = () => {
    document.querySelectorAll('.js_new-conflicts').forEach((element) => {
        try {
            let currentCount = parseInt(element.innerHTML);

            element.innerHTML = (currentCount + 1).toString();
        } catch (e) {
            console.error("Can't modify counter.")
        }
    })
}

const decreaseConflictCounter = () => {
    document.querySelectorAll('.js_new-conflicts').forEach((element) => {
        try {
            let currentCount = parseInt(element.innerHTML);

            element.innerHTML = (currentCount - 1).toString();
        } catch (e) {
            console.error("Can't modify counter.")
        }
    })
}

const toggleConflictElement = (id) => {
    document.querySelector(`#conflict-${ id }`).classList.toggle('dismissed');
    document.querySelector(`#conflict-${ id }`).classList.toggle('new');

    if(document.querySelector(`#conflict-${ id }`).classList.contains('new')) {
        document.querySelector(`#conflict-${ id }`).parentNode.prepend(document.querySelector(`#conflict-${ id }`));
    }

    if(document.querySelector(`#conflict-${ id }`).classList.contains('dismissed')) {
        document.querySelector(`#conflict-${ id }`).parentNode.append(document.querySelector(`#conflict-${ id }`));
    }
}

window.toggleConflict = (id, url, dismissOrReAdd) => {
    document.querySelector(`#conflict-${ id }`).classList.toggle('loading')
    fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(function (response) {
            document.querySelector(`#conflict-${ id }`).classList.toggle('loading')

            if(dismissOrReAdd === 'dismiss') {
                decreaseConflictCounter();
            }
            if(dismissOrReAdd === 're-add') {
                increaseConflictCounter();
            }

            toggleConflictElement(id);
        })
        .catch(function(error) {
            toastr.error(trans('messages.default_error'))
            console.log(error);
        });
}
