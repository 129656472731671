const monthSelectorEl = document.querySelector('.js-select-statistic-month');
const yearSelectorEl = document.querySelector('.js-select-statistic-year');
const formEl = document.querySelector("[name='date_filter']");
const spinnerEl = document.querySelector('.js-month-spinner');

if (monthSelectorEl) {
    var optionFormat = function(item) {
        if ( !item.id ) {
            return item.text;
        }

        let span = document.createElement('span');
        const isClosed = item.element.dataset.isClosed;
        let template = '';

        if (isClosed) {
            template += '<i class="fa fa-check mr-2 text-success"></i>';
        }

        template += item.text;
        span.innerHTML = template;

        return $(span);
    }

    $(monthSelectorEl).select2({
        templateSelection: optionFormat,
        templateResult: optionFormat,
    }).on('change', () => {
        spinnerEl.classList.toggle('hidden');
        formEl.submit();
    });

    $(yearSelectorEl).on('change', () => {
        spinnerEl.classList.toggle('hidden');
        formEl.submit();
    });
}