document.addEventListener("DOMContentLoaded", function() {
    const checkboxContainer = document.querySelector('.js-toggle-profile');
    const containers = document.querySelectorAll('.js-hidden-container');

    const toggleContainer = (inputElement) => {
        containers.forEach((container) => {
            if (inputElement.checked) {
                container.classList.add('hidden');
                return;
            }

            container.classList.remove('hidden');
        });
    }

    if (checkboxContainer) {
        const checkbox = checkboxContainer.querySelector('input');
        checkbox.addEventListener('change', (event) => {
            toggleContainer(event.target);
        });

        toggleContainer(checkbox);
    }
});

