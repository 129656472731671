const goodsPercentagesEl = document.querySelector('.js-goods-percentages');

if (goodsPercentagesEl) {
    const inputEls = goodsPercentagesEl.getElementsByTagName('input');

    inputEls.forEach(inputEl => {
        inputEl.addEventListener('change', (event) => {
            const otherInputEl = Array.from(inputEls).filter(i => event.target !== i)[0];

            if (! event.target.value.trim().length) {
                otherInputEl.value = '';

                return;
            }

            const value = parseFloat(event.target.value.replace(',', '.'));

            otherInputEl.value = isNaN(value) ? 'Ongeldig' : 100 - value;
        });
    });
}

const copyPricesEl = document.querySelector('.js-copy-prices');

if (copyPricesEl) {
    copyPricesEl.addEventListener('click', event => {
        event.preventDefault();

        Swal.fire({
            title: 'Ben je zeker?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Annuleer',
            confirmButtonColor: '#57b8ff',
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = copyPricesEl.href;
            }
        });
    });
}