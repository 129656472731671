let containers = document.querySelectorAll(".js_draggable-zone-images");

if (containers.length !== 0) {
    let sortable = new Sortable.default(containers, {
        draggable: ".draggable",
        handle: ".js_handle",
        mirror: {
            //appendTo: selector,
            appendTo: ".js_draggable-zone-images",
            constrainDimensions: true
        },
    });

    sortable.on("sortable:stop", (e) => {
        const dragEvent = e.data.dragEvent;
        let draggedNodeId = dragEvent.data.originalSource.id;
        let positionCounter = 0;

        if (e.data.newContainer.classList.contains('js_remove-zone')) {
            fetch( dragEvent.data.originalSource.dataset.removeUrl, {
                method: "GET",
            })
                .then(function(response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then(function (response) {
                    dragEvent.data.originalSource.remove()
                })
                .catch(function(error) {
                    console.log(error);
                });

            return;
        }

        dragEvent.sourceContainer.children.forEach((element) => {
            if (element.id === draggedNodeId && element.classList.contains('draggable-source--is-dragging')) {

                let actionUrl = element.dataset.sortingUrl
                actionUrl = actionUrl.replace('__REPLACE__', positionCounter);

                fetch(actionUrl, {
                    method: "GET",
                })
                .then(function(response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .catch(function(error) {
                    console.log(error);
                });
            }

            if (element.classList.contains('draggable--original') === false && element.classList.contains('draggable-mirror') === false) {
                positionCounter++;
            }
        });
    });
}

window.removeUploadedFile = (el, url) => {
    var element = el.closest('.draggable');
    fetch(url, {
        method: "GET",
    })
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(function (response) {
            element.remove();
        })
        .catch(function(error) {
            console.log(error);
        });
}
    
