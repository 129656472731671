const saveAvailabilityEl = document.querySelector('.js_save-calendar_event');
const eventTypeEls = document.querySelectorAll('input[name="event_type"]');
const eventTimesEl = document.querySelector('.js-event-times');

if (saveAvailabilityEl) {
    eventTypeEls.forEach(element => {
       element.addEventListener('click', function(e) {
           eventTimesEl.style.display = eventTimesEl.style.display === 'none' ? 'block' : 'none';
       });
    });

    saveAvailabilityEl.addEventListener('click', function(e){
        saveAvailabilityEl.querySelector('.fa-spinner').classList.toggle('d-none');

        const type = document.querySelector('input[name="event_type"]:checked').value;
        const startTime = document.querySelector('input[name="event_start_time"]').value;
        const endTime = document.querySelector('input[name="event_end_time"]').value;
        const dateString = document.querySelector('input[name="event_date"]').value;
        const name = document.querySelector('input[name="event_name"]').value;
        const dates = dateString.split(" ");
        const startDate = dates[0];
        const endDate = dates[2] ?? dates[0];
        const calendar =  document.querySelector('.js_calendar');

        if (startTime == '') {
            toastr.error(trans('common.form.start_time'));
            return;
        }

        if (endTime == '') {
            toastr.error(trans('common.form.end_time'));
            return;
        }

        if (dateString == '') {
            toastr.error(trans('common.form.select_date_range'));
            return;
        }

        fetch(saveAvailabilityEl.dataset.url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: type,
                start_date: startDate.concat(' ', startTime),
                end_date: endDate.concat(' ', endTime),
                name: name
            })
        })
        .then(function(response) {
            if (! response.ok) {
                throw response.json();
            }

            return response.json();
        })
        .then(function(json) {
            let refreshUrl = calendar.dataset.refreshurl;

            if (json.data.start_date !== 'undefined') {
                const datetime = json.data.start_date.split("T");
                const cm = new URLSearchParams(window.location.search).get('cm');

                if (2 === datetime.length) {
                    refreshUrl = `${refreshUrl}?cm=${(cm ? cm : 0)}&initialDate=${datetime[0]}`;
                }
            }

            window.location.replace(refreshUrl);
        })
        .catch(async function(error) {
            const message = (await error).errors;

            if(message) {
                return toastr.error(message);
            }

            return toastr.error(trans('messages.default_error'))
        });
    });

}
