let dirty = false;
let directionUrl = '';
const leavingPageModal = document.querySelector('#leaving-page-modal');
const leavePageTriggerElement = document.querySelector('.js_leave-page-trigger');
const leavePageCancelButton = document.querySelector('.js_leave-page-cancel');

const hideLeavePageModal = () => {
    leavingPageModal.classList.remove('show', 'd-block');
}

document.querySelectorAll('a').forEach(linkElement => {
    linkElement.addEventListener('click', event => {
        if (dirty) {
            event.preventDefault();
            directionUrl = event.target.getAttribute('href');
            leavingPageModal.classList.add('show', 'd-block');
            leavePageTriggerElement.focus();
        }
    })
})

document.querySelectorAll('.leave-page-protection input').forEach(inputElement => {
    inputElement.addEventListener('input', event => {
        dirty = true;
    })
})

document.querySelectorAll('.leave-page-protection select').forEach(selectElement => {
    selectElement.addEventListener('change', event => {
        dirty = true;
    })
})

$(".leave-page-protection select[data-control='select2']").select2().on('change', event => {
    dirty = true;
})

if (leavePageCancelButton) {
    leavePageCancelButton.addEventListener('click', event => {
        hideLeavePageModal();
    });
}

if (leavePageTriggerElement) {
    leavePageTriggerElement.addEventListener('click', event => {
        window.location.href = directionUrl;
        hideLeavePageModal();
    });
}

document.onkeydown = (evt) => {
    evt = evt || window.event;
    let isEscape = false;

    if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
        isEscape = (evt.keyCode === 27);
    }

    if (isEscape) {
        hideLeavePageModal();
    }
};