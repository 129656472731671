const bufferContainers = document.querySelectorAll('.js_buffer_container');

bufferContainers.forEach(buffer => {

    const checkOutElement = buffer.querySelectorAll('.js_buffer_start .form-select');
    const checkInElement = buffer.querySelectorAll('.js_buffer_end .form-select');

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const calculateBuffer = (buffer, checkOutElement, checkInElement) => {
        const checkOutMinutes = parseInt(checkOutElement[1].value);
        const checkOutHours = parseInt(checkOutElement[0].value);
        const checkInMinutes = parseInt(checkInElement[1].value);
        const checkInHours = parseInt(checkInElement[0].value);

        const diff = getTimeDiff(
            checkOutHours,
            checkOutMinutes,
            checkInHours,
            checkInMinutes
        );

        let bufferMinutes = diff.getMinutes();
        let bufferHours = diff.getHours() -1;

        if (checkOutHours > checkInHours) {
            bufferHours = -bufferHours;
        }

        if (!isNaN(checkOutMinutes) && !isNaN(checkInMinutes)) {
            buffer.querySelector('.js_buffer').innerHTML = `${zeroPad(bufferHours,2)}:${zeroPad(bufferMinutes, 2)}`;
        } else {
            buffer.querySelector('.js_buffer').innerHTML = '-'
        }

        // At least 2 hours buffer
        if (!isNaN(checkOutMinutes) && !isNaN(checkInMinutes) && bufferHours < 2) {
            buffer.querySelector('.js_buffer').classList.add('warning');
        } else {
            buffer.querySelector('.js_buffer').classList.remove('warning');
        }
    }

    calculateBuffer(buffer, checkOutElement, checkInElement);
    checkOutElement.forEach(element => {
        element.addEventListener("change", () => {
            calculateBuffer(buffer, checkOutElement, checkInElement);
        });
    })

    checkInElement.forEach(element => {
        element.addEventListener("change", () => {
            calculateBuffer(buffer, checkOutElement, checkInElement);
        });
    })
})

function getTimeDiff(checkOutHour, checkOutMinutes, checkInHour, checkInMinutes)
{
    const checkOutTime = new Date(1970, 1, 1, checkOutHour, checkOutMinutes).getTime();
    const checkInTime = new Date(1970, 1, 1, checkInHour, checkInMinutes).getTime();

    let miliSecDiff;
    if (checkOutTime < checkInTime) {
        miliSecDiff = checkInTime - checkOutTime;
    } else {
        miliSecDiff = checkOutTime - checkInTime;
    }

    return new Date(miliSecDiff);
}

