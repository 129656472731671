const saveICalUrlBtn = document.querySelector('.js_save_import-ical');

if (saveICalUrlBtn) {

    saveICalUrlBtn.addEventListener('click', function(e){
        saveICalUrlBtn.querySelector('.fa-spinner').classList.toggle('d-none');

        const iCalUrl = document.querySelector('input[name="ical_url"]').value;

        fetch(saveICalUrlBtn.dataset.url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: iCalUrl
            })
        })
            .then((response) => {
                saveICalUrlBtn.querySelector('.fa-spinner').classList.toggle('d-none');

                if (response.ok) {
                    window.location.reload();

                    return response;
                }

                return response.text().then((text) => {
                    const data = JSON.parse(text);

                    throw Error(data.errors)
                });

            })
        .catch(function(error) {
            toastr.error(error)
            console.log(error);
        });
    });

}