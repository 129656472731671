const cancelModalTriggerEls = document.getElementsByClassName('js-cancel-trigger');

if (cancelModalTriggerEls) {
    cancelModalTriggerEls.forEach(element => {
        element.addEventListener('click', function(e) {
            const cancelModalTitleEl = document.querySelector('.js-cancel-modal-title');
            const cancelModalSubmitEl = document.querySelector('.js-cancel-modal-submit');

            cancelModalSubmitEl.href = element.dataset.href;
            cancelModalTitleEl.innerHTML = element.innerText.toLowerCase();
        });
    });
}