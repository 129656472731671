const inputEls = document.getElementsByClassName('js-capacity-sum');
const totalEls = document.getElementsByClassName('js-capacity-total');

if (inputEls) {
    inputEls.forEach(inputEl => {
        inputEl.addEventListener('change', (event) => {
            const period = event.target.dataset.period;
            const otherInputsEl = Array.from(inputEls).filter(i => event.target !== i && i.dataset.period === period);
            let totalEl = Array.from(totalEls).find(i => i.dataset.period === period);

            let value = parseFloat(event.target.value.replace(',', '.')) || 0;

            if(otherInputsEl.length > 0) {
                const otherValue = otherInputsEl.map(el => parseFloat(el.value.replace(',', '.')) || 0).reduce((previousValue, cv) => previousValue + cv);
                totalEl.value = value + otherValue;
                return ;
            }

            totalEl.value = value;
        });
    });
}