if (document.querySelector('#map')) {

    const errorMsg = document.querySelector('#map').dataset.errorMessage;
    console.log(errorMsg);

    const platform = new H.service.Platform({
        apikey: window.apikey
    });


    // FLAG SVG

    // const svgMarkup = '<svg width="48" height="24" style="overflow: visible" viewBox="0 0 512.004 512.004" ' +
    //     'xmlns="http://www.w3.org/2000/svg">' +
    //     '<g transform="translate(200 0)">' +
    //     '<path d="M365.452,308.98c-35.723,0.724-70.246-12.958-95.774-37.957c-22.209-21.6-52.177-33.375-83.152-32.66  c-26.075-0.344-51.559,7.839-72.559,23.304c-2.675,2.092-5.958,3.24-9.357,3.266c-4.546-0.035-8.854-2.03-11.828-5.473  c-2.957-3.398-4.555-7.768-4.502-12.27V48.314c0-4.864,1.942-9.533,5.385-12.976c25.316-23.18,58.542-35.812,92.861-35.309  c35.785-0.759,70.37,12.914,95.951,37.957c22.156,21.574,52.054,33.34,82.975,32.66c21.265,0.194,42.22-5.129,60.819-15.447  c2.322-1.395,4.97-2.154,7.68-2.207c9.339,0.433,16.595,8.306,16.242,17.654v200.817c0.168,5.967-2.692,11.608-7.591,15.006  C419.686,301.468,392.834,309.306,365.452,308.98z" fill="#fe8361"/>' +
    //     '<path d="M79.453,0.03L79.453,0.03c9.754,0,17.654,7.9,17.654,17.654v476.665  c0,9.754-7.9,17.654-17.654,17.654l0,0c-9.754,0-17.654-7.9-17.654-17.654V17.684C61.799,7.939,69.699,0.03,79.453,0.03z" fill="#57b8ff" />' +
    //     '</g>' +
    //     '</svg>';

    const svgMarkup = '<svg width="36" height="47" viewBox="0 0 36 47" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V29.7504C36 32.4077 34.6806 34.8915 32.4789 36.3792L22.1057 43.3881C19.3366 45.259 15.6963 45.2117 12.9769 43.2692L3.3501 36.3929C1.24773 34.8912 0 32.4667 0 29.8831V8Z" fill="#7DCE82"/>\n' +
        '<g clip-path="url(#clip0)">\n' +
        '<path d="M28.7314 16.3519L18.6481 6.2686C18.4762 6.09675 18.2431 6.00021 18 6.00021C17.757 6.00021 17.5238 6.09675 17.3519 6.2686L7.2686 16.3519C7.14044 16.4801 7.05317 16.6434 7.01782 16.8212C6.98246 16.999 7.00062 17.1833 7.06998 17.3508C7.13934 17.5183 7.2568 17.6614 7.40751 17.7621C7.55822 17.8629 7.73541 17.9166 7.91668 17.9167H9.06252C9.1233 17.9167 9.18159 17.9408 9.22456 17.9838C9.26754 18.0268 9.29168 18.0851 9.29168 18.1459V27.0834C9.29168 27.3265 9.38826 27.5596 9.56017 27.7315C9.73208 27.9034 9.96524 28 10.2084 28H15.4792C15.54 28 15.5983 27.9759 15.6412 27.9329C15.6842 27.8899 15.7084 27.8316 15.7084 27.7709V23.4167C15.7084 22.8089 15.9498 22.226 16.3796 21.7962C16.8093 21.3665 17.3922 21.125 18 21.125C18.6078 21.125 19.1907 21.3665 19.6205 21.7962C20.0502 22.226 20.2917 22.8089 20.2917 23.4167V27.7709C20.2917 27.8316 20.3158 27.8899 20.3588 27.9329C20.4018 27.9759 20.4601 28 20.5209 28H25.7917C26.0348 28 26.268 27.9034 26.4399 27.7315C26.6118 27.5596 26.7083 27.3265 26.7083 27.0834V18.1459C26.7083 18.0851 26.7325 18.0268 26.7755 17.9838C26.8184 17.9408 26.8767 17.9167 26.9375 17.9167H28.0833C28.2646 17.9166 28.4418 17.8629 28.5925 17.7621C28.7432 17.6614 28.8607 17.5183 28.9301 17.3508C28.9994 17.1833 29.0176 16.999 28.9822 16.8212C28.9469 16.6434 28.8596 16.4801 28.7314 16.3519Z" fill="white"/>\n' +
        '</g>\n' +
        '<defs>\n' +
        '<clipPath id="clip0">\n' +
        '<rect width="22" height="22" fill="white" transform="translate(7 6)"/>\n' +
        '</clipPath>\n' +
        '</defs>\n' +
        '</svg>';
    const icon = new H.map.Icon(svgMarkup);

    const defaultLayers = platform.createDefaultLayers();

    const map = new H.Map(document.getElementById('map'),
        defaultLayers.vector.normal.map, {
            center: {lat:51.0753416, lng:4.4472116},
            zoom: 8 ,
            pixelRatio: window.devicePixelRatio || 1
        });
    window.addEventListener('resize', () => map.getViewPort().resize());

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));


    map.addEventListener('dragstart', function(ev) {
        var target = ev.target,
            pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
            var targetPosition = map.geoToScreen(target.getGeometry());
            target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
            behavior.disable();
        }
    }, false);

    map.addEventListener('dragend', function(ev) {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
            behavior.enable();
            setLocationCoordFields(target.a)
            map.setCenter(target.a);
        }
    }, false);

    map.addEventListener('drag', function(ev) {
        var target = ev.target,
            pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
            target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
        }
    }, false);

    var ui = H.ui.UI.createDefault(map, defaultLayers, 'nl-NL');

    window.marker = null;

    // SEARCH

    document.querySelector('.js_place-marker').addEventListener('click', () => {
        calculateLocation();
    });

    document.querySelector('.js_submit-location-form').addEventListener('click', (event) => {
        if(!document.querySelector('.js_pin-lat').value && !document.querySelector('.js_pin-lng').value) {
            event.preventDefault();
            calculateLocation();
            document.querySelector('#map-location-form').submit();
        }
    });

    document.querySelector('.js_pin-lat').addEventListener('input', (event) => {
        if(document.querySelector('.js_pin-lat').value && document.querySelector('.js_pin-lng').value) {
            init();
        }
    });

    document.querySelector('.js_pin-lng').addEventListener('input', (event) => {
        if(document.querySelector('.js_pin-lat').value && document.querySelector('.js_pin-lng').value) {
            init();
        }
    });

    const calculateLocation = () => {
        const locationString = getLocationString();

        if (!locationString) {
            toastr.warning(errorMsg);
            return;
        }

        getLatLongOfLocation(locationString).then((latLong) => {
            if (!latLong) {
                toastr.warning(errorMsg);
                return;
            }

            setMarker(latLong);
            setLocationCoordFields(latLong)
        });
    }

    const getLatLongOfLocation = async (location) => {
        return fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${location}&apiKey=${window.apikey}`)
            .then(response => response.json())
            .then(data => {
                return data['items'][0]['position'];
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
                return null;
            });
    };

    const setMarker = (coords) => {
        if (window.marker) {
            map.removeObject(window.marker)
        }

        window.marker = new H.map.Marker(coords, {
            volatility: true,
            icon: icon
        });

        window.marker.draggable = true;
        map.addObject(window.marker);
        map.setZoom(18);
        map.setCenter(coords);
    }

    const getLocationString = () => {
        const street = document.querySelector('.js_map-search-street').value;
        const house_nr = document.querySelector('.js_map-search-nr').value;
        const postal = document.querySelector('.js_map-search-postal').value;
        const city = document.querySelector('.js_map-search-city').value;
        const country = document.querySelector('.js_map-search-country').value;

        const locationArray = [street, house_nr, postal, city, country];
        let locationString = '';

        locationArray.forEach(locationValue => {
            if (locationValue) {
                locationString = `${locationString}+${locationValue}`;
            }
        })

        return locationString;
    }

    const setLocationCoordFields = (coords) => {
        document.querySelector('.js_pin-lat').value = coords.lat;
        document.querySelector('.js_pin-lng').value = coords.lng;
    }


    // INIT MARKER

    const init = () => {
        try {
            const lat = parseFloat(document.querySelector('.js_pin-lat').value);
            const lng = parseFloat(document.querySelector('.js_pin-lng').value);

            if (lat && lng) {
                setMarker({lat: lat, lng: lng})
            }
        } catch (err) {
            console.error('Given Lat lng is not numeric')
            return;
        }
    }

    init();
}
