import axios from "axios";

const csvExportForm = document.getElementById('form_manager_csv_export');

if (csvExportForm) {
    csvExportForm.addEventListener("submit", (e) => {
        e.preventDefault();

        const button = csvExportForm.querySelector('button');
        const formWrapper = document.getElementById('nights_per_booking_form_wrapper');
        const responseContainer = document.getElementById('nights_per_booking_response');
        button.setAttribute("disabled", "disabled");

        const json = JSON.stringify({
            email: document.getElementById('nights_per_booking_email').value,
            year: parseInt(document.getElementById('nights_per_booking_year').value),
            logisDecreet: parseInt(document.getElementById('nights_per_booking_logisDecreet').value),
            token: document.getElementById('nights_per_booking__token').value,
        });

        axios.post(csvExportForm.dataset.url, json)
            .then((response) => {
                responseContainer.innerHTML = response.data
                responseContainer.classList.remove('d-none','red');
                responseContainer.classList.add('d-flex','green');
                formWrapper.classList.remove('d-flex');
                formWrapper.classList.add('d-none');
        }).catch(error => {
            responseContainer.innerHTML = error.response.data
            responseContainer.classList.remove('d-none','green');
            responseContainer.classList.add('d-flex','red')
            button.removeAttribute("disabled");
        })
    });
}
